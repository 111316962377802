<template>
  <div class="social-media">
    <property-title :backgroundTitle="backgroundTitle" :title="title" />
    <div class="media-content">
      <div class="content">
        <div class="select-box">
          <select
            v-model="value"
            @change="changeCity"
          >
            <option
              v-for="item in options"
              :key="item.id"
              :label="item.area"
              :value="item.area"
            ></option>
          </select>
          <div class="selectIcon"><img src="../../assets/images/down.png" /></div>
        </div>
        <div class="select-box">
          <select
            v-model="value1"
            @change="changeImg"
          >
            <option
              v-for="item in options1"
              :key="item.id"
              :label="item.department"
              :value="item.department"
            ></option>
          </select>
          <div class="selectIcon"><img src="../../assets/images/down.png" /></div>
        </div>
        <div class="img">
          <img :src="src" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PropertyTitle from "../../components/common/PropertyTitle";
import { apiGetQrcode, apiGetQrcodeById } from "../../api/index";
export default {
  components: {
    PropertyTitle,
  },
  metaInfo: {
    title: '爱情地产',
    meta: [{
      name: 'keyWords',
      content: '爱情地产'
    },
    {
      name: 'description',
      content: ''
    }]
  },
  data() {
    return {
      backgroundTitle: "MEDIA",
      title: "社交媒体",
      options: [],
      value: "2",
      value1: "1",
      options1: [],
      src: "",
    };
  },
  methods: {
    changeCity() {
      apiGetQrcodeById(this.value).then((data) => {
        if (data.data.status == 1) {
          this.options1 = data.data.address;
          this.value1 = data.data.address[0].department;
          console.log(this.value1)
          this.src =
            "http://www.weaiove.com" + data.data.address[0].photo;
        }
      });
    },
    changeImg() {
      this.options1.forEach((item) => {
        if (item.department === this.value1) {
      console.log(this.value1,item)
          this.src = "http://www.weaiove.com" + item.photo;
        }
      });
    },
    init() {
      apiGetQrcode().then((data) => {
        if (data.data.status == 1) {
          this.options = data.data.area;
          this.value = this.options[0].area;
          this.changeCity();
        }
      });
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style lang="less" scoped>
.property-title {
  margin-bottom: 67px;
}
.media-content {
  width: 100%;
  // height: 400px;
  padding: 20px 160px;
  .content {
    width: 100%;
    height: 567px;
    background: #f8f8f8;
    padding: 55px 200px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .select-box + .select-box{
      margin-left: 61px;
    }
    .select-box {
      width: 452px;
      height: 88px;
      position: relative;
      background: #f6ebe4;
      .selectIcon{
        position: absolute;
        right: 23px;
        top: 50%;
        margin-top: -16px;
        width: 32px;
        height: 32px;
        img{
          width: 100%;
          height: 100%;
        }
      }
      select{
        width: 100%;
        height: 100%;
        font-size: 24px;
        appearance:none;
        background: transparent;
        color: #E95506;
        padding: 28px 48px;
        border: none;
        option{
          color: #999;
          font-size: 18px;
        }
      }
    }
    .img {
      width: 278px;
      height: 278px;
      margin: 74px auto;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
